<template>
  <div>
    <v-form>
      <p class="mb-5">
        In the presence of a base and under heat, 1-bromobutane will undergo a dehydrohalogenation
        reaction resulting in the formation of an alkene (see Scheme below).
      </p>

      <p class="mb-5 pl-8">
        <v-img src="/img/assignments/UCI_51LB_A3_Q8.png" max-width="306px" />
      </p>

      <p class="mb-n3">
        Which of the compounds below would be the expected organic product of the reaction?
      </p>
      <v-radio-group v-model="inputs.studentAnswer" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemUCI51LBA3_Q8',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
      options: [
        {text: '1-butene', value: '1butene'},
        {text: '2-butene', value: '2butene'},
        {text: '3-butene', value: '3butene'},
        {text: '1-butene and 2-butene', value: '12butene'},
      ],
    };
  },
};
</script>
